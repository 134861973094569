<script>
import CrudFieldSelectorBattleActiveSkills
  from '@/components/forms/crud-fields/CrudFieldSelectorBattleActiveSkills.vue'

export default {
  components: { CrudFieldSelectorBattleActiveSkills }
}
</script>

<template>
  <crud-details-page
    api="battle/pet"
    disable-top-margin
  >
    <template #form>
      <fields-col>
        <crud-field-selector-client-strings
          :sm="8"
          api-field-name="Name"
        />
        <crud-field-text
          :sm="8"
          api-field-name="Label"
        />
        <crud-field-selector-items
          :sm="8"
          api-field-name="Food"
          :init-filters="{ Class: 'Usable' }"
        />
        <CrudFieldSelectorBattleActiveSkills
          :sm="8"
          api-field-name="MainSkill"
        />
        <crud-field-number
          :sm="4"
          api-field-name="Cooldown"
        />
        <crud-field-number
          :sm="4"
          api-field-name="ScaleHero"
          :precision="6"
        />
        <crud-field-number
          :sm="4"
          api-field-name="ScaleSkillTree"
          :precision="6"
        />
        <crud-field-number
          :sm="4"
          api-field-name="ScaleArena"
          :precision="6"
        />
      </fields-col>

      <fields-col
        :md="17"
        :xl="16"
      >
        <div class="w-full pl-4">
          <fields-table>
            <template #tHeader>
              <tr>
                <th>Node</th>
                <th>Skill</th>
                <th>ReqPts</th>
                <th>UnlockCost</th>
                <th>IsFinal</th>
                <th>Asset</th>
                <th>Color</th>
              </tr>
            </template>
            <template #tBody>
              <tr
                v-for="row in 15"
                :key="row"
              >
                <fields-table-td
                  :width="70"
                  class="text-center"
                >
                  {{ row }}
                </fields-table-td>
                <fields-table-td
                  :width="230"
                >
                  <crud-field-selector-battle-passive-skills
                    :api-field-name="`Node${row}.Skill`"
                    slim
                    :label="false"
                  />
                </fields-table-td>
                <fields-table-td :width="100">
                  <crud-field-number
                    :api-field-name="`Node${row}.ReqPts`"
                    slim
                    :label="false"
                  />
                </fields-table-td>
                <fields-table-td :width="100">
                  <crud-field-number
                    :api-field-name="`Node${row}.UnlockCost`"
                    slim
                    :label="false"
                  />
                </fields-table-td>
                <fields-table-td :width="70">
                  <crud-field-checkbox
                    :api-field-name="`Node${row}.IsFinal`"
                    slim
                    :label="false"
                  />
                </fields-table-td>
                <fields-table-td :width="230">
                  <crud-field-selector-files
                    :api-field-name="`Node${row}.IconAsset`"
                    slim
                    :label="false"
                  />
                </fields-table-td>
                <fields-table-td :width="100">
                  <crud-field-text
                    :api-field-name="`Node${row}.IconColor`"
                    slim
                    :label="false"
                  />
                </fields-table-td>
              </tr>
            </template>
          </fields-table>
        </div>
      </fields-col>
      <fields-col
        :sm="8"
        :md="7"
        :xl="8"
      >
        <crud-field-linkage-graph
          api-field-name="TreeCoordinates"
          class="pt-4"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
